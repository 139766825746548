import React from "react";
import { HashLink as Link } from 'react-router-hash-link'
 
export default function Footer() {
  let date = new Date(),
    dateYear = date.getFullYear();

  return (
    <footer className=" p-5" style={{ backgroundColor: "#fafafa" }}>
      <div className="container">
        <div className="row flex-row-reverse">
          {/* Box */}
          <div className="box col-12 col-md-6 col-lg-6 text-end ps-5">
            <h4 className="fw-bold">اكسترافا</h4>
            <p className="mt-3">
            نسعى في اكسترافا لأن نكون جزءاً من حياتك اليومية، لنساعدك على التنقل بأسلوب يعكس رفاهيتك وراحتك
            </p>
            <p>
              {/* لتجربة تنقل مميزة تضيف لمسة من الفخامة إلى يومك */}
              </p>
          </div>
          {/* Box */}
          <AllLinks/>
        </div>
      </div>
      <div className="p-2 text-center container">
        <div className="border border-1 my-3"></div>© <span>{dateYear} </span>
          Extrava.shop جميع الحقوق محفوظة لدى  موقع  
      </div>
    </footer>
  );
}


function AllLinks() {

  let linkPolicy = [
    {name:" الساسية و الخصوصية", link:"policy"},
    // {name:"معلومات الشحن", link:"transport"},
    {name:"سياسة الإسترجاع",link:"return"},
  ]

  return (
    <>
           <div className="box  col-12 col-md-6 col-lg-3 text-end">
            <h4 className="fw-bold">الروابط </h4>
            <ul className="d-flex flex-column gap-3 mt-3">
              {linkPolicy.map(({name,link},index) =>{ 
                return  <li key={index}>
                <Link className="" to={`/${link}#first`}>
                  {name}
                </Link>
              </li>
              })}
              
            </ul>
          </div>
          <div className="box col-12 col-md-6 col-lg-3 text-end">
            <h4 className="fw-bold">تواصل معنا </h4>
            <ul className="d-flex flex-column gap-3 mt-3">
              <li>
                <Link className="" to="https://wa.me/966508630084">
                  الواتساب
                </Link>
              </li>
              
            </ul>
          </div>
    </>
  )
}
