import React from 'react'
import { HelmetProvider, Helmet } from 'react-helmet-async';

export default function ParentSEO({children,title,content}) {
  return (
    <HelmetProvider>
    <Helmet>
      <title>اكسترافا - {title}</title>
      <meta name="description" content={content} />
    </Helmet>
        {children}
    </HelmetProvider>
    
  )
}